<template>
  <v-container fluid ma-0 pa-0>
    <v-layout row wrap>
      <v-flex xs12 id="quoter">
        <quoter-uber />
        <apps-companies class="px-3 light-background" />
        <how-it-works />
        <payment-methods class="px-3 light-background" />
        
        <p class="graphik-medium text-xs-center secondary--text mt-5" :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_benefits_step_1') }}</p>

        <p class="graphik-light text-xs-center mt-3 mb-0" :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_most_quality_description') }}</p>

        <companies-static :companies="companies" class="px-2" />
        <reviews class="px-2 light-background" />

        <v-layout wrap>
          <v-flex xs12 text-xs-center primary py-5 px-3>
            <p class="graphik-bold white--text" :class="{'font-32': $vuetify.breakpoint.smAndUp, 'font-25': $vuetify.breakpoint.xsOnly}">{{ $ml.get('landing_car_quote') }}</p>
            <p class="graphik-light white--text" :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('landing_car_description') }}</p>
            
            <v-btn 
              color="pantene"
              depressed dark large
              :loading="g_isLoading"
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              @click="$vuetify.goTo('#quoter')"
            >{{ $ml.get('general_quote') }}</v-btn>
          </v-flex>
        </v-layout>

        <p class="graphik-medium text-xs-center secondary--text my-3 px-3" :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_title') }}</p>

        <p class="graphik-light text-xs-center my-3 px-3" :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_products_description') }}</p>

        <products class="px-3 my-5" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ProductUber',
  components: {
    'quoter-uber': () => import(/* webpackPrefetch: true */ '../components/QuoterUber'),
    'apps-companies': () => import(/* webpackPrefetch: true */ '../components/ContentAppsCompanies'),
    'companies-static': () => import(/* webpackPrefetch: true */ '../components/ContentCompaniesStatic'),
    'how-it-works': () => import(/* webpackPrefetch: true */ '../components/ContentHowItWorksManual'),
    'payment-methods': () => import(/* webpackPrefetch: true */ '../components/ContentPaymentMethods'),
    'reviews': () => import(/* webpackPrefetch: true */ '../components/ContentReviews'),
    'products': () => import(/* webpackPrefetch: true */ '../components/ContentProducts')
  },
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_UBER,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_UBER
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_UBER
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_UBER
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_UBER
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_UBER
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/uber'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/uber'
      }
    ]
  },
  data () {
    return {
      companies: ['axa', 'primeroSeguros', 'gnp', 'qualitas']
    }
  }
}
</script>

<style scoped>
</style>
